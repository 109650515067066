$accent_color: darken(#b8d433, 10);
$color1: #2b3d4c;
$green: $accent_color;
$gray: #555;
$gray-light: #747374;
$gray-lightest: #bdc1c3;
$yellow: #f0ad4e;
$orange: #f28b00;
$red: #d9534f;
$blue_text: #3a5266;

$size-small: 5px;
$size-normal: 15px;
$size-large: 30px;

html,
body,
#root {
  // position: relative;
  // height: auto;
  // min-height: 100%;
  position: relative;
  height: 100%;
}

.mb-normal {
  margin-bottom: $size-normal;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

#wrapper {
  min-height: 100%;
  height: auto;
  padding: 0 0 60px;
  margin: 0 auto -60px;
}

body.landing {
  margin-bottom: 282px;
  background-color: #fff;
}

.footer-container {
  position: absolute;
  height: 282px;
  bottom: 0;
  width: 100%;
}

.landing .footer {
  height: auto;
  padding-bottom: 0;
}

.leaflet-container {
  height: 300px;
  width: 100%;
  margin: 0 auto;
}

.list-unstyled {
  margin-bottom: 0;
}

.thumb {
  max-width: 200px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexEndRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fa.map {
  color: $red;
  font-size: 24px;
}

.flexCol {
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }
}

.icon-list {
  //background-color: 'silver';
  .horizontal {
    display: flex;
    > li {
      margin-right: $size-normal;
    }
  }

  > li {
    padding: 5px 0 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > i.fa {
      margin-right: $size-normal;
      font-size: 24px;
      color: $gray-light;
      min-width: 25px;
      text-align: center;
    }

    .flexCol {
      > label {
        margin-bottom: 0;
      }

      > span {
        margin-bottom: 0;
        color: $gray-lightest;
      }
    }
  }
}

.icon-list > li .flexCol .icon-list > li > i.fa {
  margin-right: $size-normal;
  font-size: 24px;
  color: $gray-light;
  min-width: 25px;
  text-align: center;
}

.alert {
  display: flex;
  padding: 0;
  border-color: #00000030;
  align-items: center;
  > i {
    padding: 15px 15px;
    font-size: 24px;
    background-color: #00000030;
  }
  > span {
    padding: 0 15px;
  }
}

.alert b,
.alert a,
.alert a:hover,
.alert a:active,
.alert a:visited {
  color: #fff;
  text-decoration: underline;
}

.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  background-color: transparent;
}

.navbar-brand img {
  height: 50px;
}

a.btn-default {
  color: #000 !important;
}

.btn h1,
.btn h3 {
  margin-bottom: 0;
}

.btn h3 {
  font-size: 22px;
}

.upper,
.btn h1 {
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.masthead-carousel .carousel-control {
  font-size: 30px;
  height: auto;
}

.select__menu {
  z-index: 100 !important;
  position: absolute;
}

/*Parsley*/
.parsley-form {
  position: relative;
}
.parsley-form .parsley-success .parsley-error-list {
  display: none !important;
}
.parsley-form .parsley-error-list {
  position: relative;
  top: 0;
  display: none !important;
  padding: 2px 10px;
  margin: 0;
  list-style: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background: #d9534f;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.parsley-form .parsley-error-list > li {
  list-style-type: none;
}
.parsley-form .parsley-error-list:before,
.parsley-form .parsley-error-list:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d9534f;
  position: absolute;
  top: -6px;
  left: 3px;
}
.parsley-form .parsley-error-list.filled {
  display: inline-block !important;
}
.parsley-form.form-horizontal .form-group span.error {
  margin-left: 1.5em;
  margin-left: 15px;
}
.parsley-form .parsley-error-list {
  // position: absolute;
  // bottom: -30px;
  // left: 0;
  // top: auto;

  position: relative;
  top: 7px;
  left: auto;
  right: 0;

  color: #bf2e29;
  background: #f9e2e2;
  border: 1px solid #d9534f;
}

.account-form .form-control {
  margin-bottom: 0;
}

.form-control {
  height: 38px;
}

.input-group-btn .btn {
  padding-top: 8px;
  padding-bottom: 8px;
}

.parsley-form .parsley-error-list::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 5px;
  z-index: 10;
  border-bottom: 6px solid #f9e2e2;
  border-top: none;
}
.parsley-form .parsley-error-list:after {
  top: -8px;
  left: 5px;
  z-index: 9;
  margin-top: 1px;
  border-bottom: 6px solid #b52b27;
  border-top: none;
}
/* /Parsley */
/* Landing */

.landing .navbar {
  background-color: #fff;
}

.landing .nav .open > a,
.landing .nav .open > a:hover,
.landing .nav .open > a:focus,
.landing .navbar .navbar-nav > li > a:hover,
.landing .navbar .navbar-nav > li > a:focus {
  color: #444444;
  background-color: #eeeeee;
  border: none;
}

.landing .navbar-nav > li.active > a,
.landing .navbar-nav > li.active > a:hover {
  background: transparent;
  border-bottom: none;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Oswald', 'Trebuchet MS', 'Open Sans', arial, sans-serif;
  background: transparent;
  border: none;
}

.landing .navbar-nav > li > a {
  padding: 10px 12px;
  font-weight: normal;
  color: #888888;
}

.mainnav-menu > li > a {
  font-size: 16px;
  letter-spacing: inherit;
}

.navbar .dropdown-menu {
  min-width: 215px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.landing .mainnav-menu > li > .dropdown-menu:before,
.landing .mainnav-menu > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  right: 9px;
  left: auto;
}
.landing .mainnav-menu > li > .dropdown-menu:after {
  border-left-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-bottom-color: #fff;
  top: -6px;
  right: 10px;
  left: auto;
}

.landing .content {
  margin-bottom: 0;
}

h4.masthead-title,
h2.masthead-title,
.btn h1,
.btn h3 {
  color: #fff;
}

.panel-default > .panel-heading {
  .badge.badge-primary {
    background-color: $green;
  }
}

h5 {
  .badge {
    margin-left: 5px;
    font-weight: normal;
    font-family: 'Trebuchet MS', sans-serif;
  }

  .badge-primary {
    background-color: $green;
  }

  .badge-warning {
    background-color: $yellow;
    //color: $gray;
  }

  .badge-default {
    background-color: white;
    color: $gray;
    border: solid 1px $gray;
  }

  .badge-danger {
    background-color: $red;
  }
}

.dropdown-menu > li.danger > a {
  color: $red;
}

//
.my-dropdown-menu {
  display: none;
}

.open .my-dropdown-menu {
  display: block;
}

// .dropdown-menu > li {
//   padding-top: 20px;
//   padding-bottom: 20px;
//   background-color: #2196f3;
// }

.page-title,
.nav-tabs > li > a {
  text-transform: capitalize;
}

.nav-tabs > li > a > .badge {
  margin-left: 5px;
}

.savingcard.portlet-boxed .portlet-title:after {
  display: none;
}

.cursor {
  cursor: pointer;
}

.savingcard.portlet.details {
  cursor: default;
}

.portlet-boxed .portlet-title:after {
  z-index: 0;
}

.details img {
  width: 100%;
}

.panel-title {
  color: #435f76;
}

h4.panel-title {
  font-size: 18px;
  display: block;
  margin-bottom: 4px;
}

.portlet-boxed h5.portlet-title,
h5.panel-title {
  font-size: 12px;
  color: #555;
  display: block;
}

.bgimg .portlet-body,
.portlet-body .portlet-body {
  //background-image: url(https://www.mycashless.nl//localizedimage/index/ba78a714-fbfc-4523-b0ed-41e49e1b8670);
  background-size: cover;
  min-height: 300px;
  background-position: center;
}

.min-y-300 {
  min-height: 300px;
}

.bgimg {
  background-size: cover;
  background-position: center;
}

.nopad {
  padding: 0;
}

.panel-footer {
  i {
    font-size: 72px;
    margin-left: 10px;
  }
}

.portlet-footer h4,
.panel-footer h4 {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.savingcard .portlet-body {
  padding: 0 !important;
  position: relative;
}

.portlet-footer {
  padding: 20px 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  i {
    font-size: 24px;
    margin-left: 10px;
  }
}

.circle-stat {
  border-radius: 50px;
  height: 75px;
  width: 75px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  //box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
}

.mybility .portlet-footer h4 {
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

.circle-stat h4 {
  color: #fff;
  margin-bottom: 0;
}

.circle-stat span,
.circle-stat i.fas {
  position: absolute;
  right: 7px;
  bottom: -7px;
  font-size: 36px;
  color: rgba(255, 255, 255, 0.3);
}

.circle-stat i.fas {
  bottom: 0;
}

.circle-stat span.lg {
  font-size: 60px;
  bottom: -20px;
  right: 1px;
}

.modal {
  display: block;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.4);
}

.account-form .placeholder-hidden {
  display: none;
}

.ck-editor__editable {
  min-height: 150px;
}

.badge.info {
  cursor: pointer;
  padding: 6px 8px;
}

label {
  //info questionsign
  i.popover-info {
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
  }

  .popover {
    max-width: 400px;
    min-width: 300px;
  }
}

.inline-check {
  .form-group {
    display: inline;
  }
  .checkbox {
    display: inline-block;
    label {
      margin-right: 8px;
    }
  }
}

.checkbox {
  margin-top: 0;
  margin-bottom: 0;
  input[type='checkbox'] {
    margin-top: 2px;
  }
  label {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.portlet-body .alert {
  margin: $size-normal 0;
}

.tile.saving-card {
  background-size: cover;
}

.table.card tr {
  background-color: #fafafa;
}

.my-card {
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  background-color: #fff;
  margin-bottom: 16px;
  .header-bg {
    background-size: cover;
    min-height: 190px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .footer-title {
    padding: 16px 16px 0 16px;
    height: auto;
    //background-color: rgb(231, 235, 240);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 0.875rem;
    color: $blue_text;
    .badge {
      margin-left: 0;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 6px;
      padding-right: 6px;
      background-color: $accent_color;
    }
    h4 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      letter-spacing: 0.0125em;
      margin-bottom: 0;
    }
    h5 {
      font-size: 0.875rem;
      font-family: 'arial';
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.0178571429em;
    }
  }
  .footer-text {
    padding: 16px 16px 0 16px;
    height: auto;
    font-size: 0.875rem;
    color: $blue_text;

    .key-value {
      display: flex;
      justify-content: space-between;
      label {
        font-weight: normal;
      }
    }
  }
  .footer-actions {
    padding: 8px;
    display: flex;
    justify-content: space-between;

    a {
      padding-left: 8px;
      padding-right: 8px;

      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 2.25rem;
      font-weight: 600;
      letter-spacing: 0.0892857143em;
      &:hover {
        background-color: lighten($accent_color, 50);
        text-decoration: none;
      }
    }
    a.danger {
      color: $red;
      &:hover {
        background-color: lighten($red, 40);
      }
    }
  }

  display: flex;
  flex-direction: column;
}

.tile {
  height: 190px;
  background-color: #cecece;
  display: flex;
  align-items: flex-end;
}

.tile .container {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.tile h4 {
  font-size: 18px;
  margin-top: 0.75em;
  color: #fff;
  text-align: center;
}

.stamp-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

hr.stamp-hr {
  border-color: #000;
}

.stamp {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

.stamp-border {
  width: 100%;
  margin: 10px;
  border: 2px solid #000;
  border-radius: 50% !important;
}

.stamp img {
  height: 100%;
  position: absolute;
}
/*
.stamp:not(:nth-child(5n)) {
  margin-right: 2.5%;
}
*/
.stamp:before {
  content: '';
  float: left;
  padding-top: 100%;
}

.tile .coupon-logo .fa {
  margin-top: 10px;
  font-size: 24px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

html {
  font-size: 16px;
}

.select__control {
  min-height: 34px !important;
}

.select__dropdown-indicator {
  padding-bottom: 0;
  padding-top: 0;
}

.mainnav-menu .fa-chevron-down:before {
  margin-left: 0.375em;
}

.bottom-left {
  position: fixed;
  right: 15px;
  bottom: 15px;
}

.circle-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .circle-header {
    display: flex;
    font-size: 18;
    justify-content: space-between;
    h5 {
      width: 75px;
      margin-right: 15px;
      text-align: center;
    }
  }

  .circle-stat-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > span {
      border-bottom: solid 1px $gray;
    }

    .circle-stat {
      margin-right: 15px;
    }
  }
}

hr {
  border-top: 1px solid #eee;
  width: 100%;
}

.details {
  label {
    display: block;
  }
}

.example-enter {
  opacity: 0.01;
  height: 0;
}

.example-enter.example-enter-active {
  opacity: 1;
  height: 200px;
  transition: opacity 1500ms ease-in, height 1500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.button-row {
  display: flex;
}

.button-row.right {
  justify-content: flex-end;
  > .btn {
    margin-left: $size-small;
  }
}

.button-row.left {
  justify-content: flex-start;
  > .btn {
    margin-right: $size-small;
  }
}

.tag {
  margin-right: 5px;
  > .btn-default {
    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      box-shadow: none;
      outline: none;
    }
    cursor: default;
  }
}

.mr-5 {
  margin-right: $size-small;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.dropzone img,
.modal img.preview {
  max-width: 100%;
}

.dropzone span {
  padding: 10px;
  color: #000;
}

.sepa td {
  background-color: #eee;
}

//fixe select2
.modal-body {
  overflow: visible;
}

.table {
  tbody {
    hr {
      border-color: #555;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    label {
      margin-bottom: 0px;
      display: block;
    }

    span {
      margin-bottom: 10px;
      display: block;
    }

    .id {
      width: 100px;
    }

    .row.margin-bottom {
      margin-bottom: 10px;
    }
  }
}

.pagination-total {
  margin-left: 10px;
}

.btn.btn-xs.margin-right {
  margin-right: 5px;
}

.btn.btn-xs.margin-left {
  margin-left: 5px;
}

.modal-body {
  max-height: calc(100vh - 212px);
  overflow: visible;
}

.max-height .modal-body {
  max-height: 600px;
  overflow: scroll;
}

.horizontal-scroll {
  display: flex;
  overflow-x: hidden;
  flex-direction: row;
}

.h-slide {
  flex: none;
  width: 100%;
  transition: all 300ms;
}

.h-slide.left {
  margin-left: -100%;
}

a {
  cursor: pointer;
}

.fa-map-marker.map,
.fa-map-marker-alt.map {
  color: $orange;
  font-size: 40px;
  margin-top: -30px;
  margin-left: -10px;
}

.mapContainer {
  height: 400px;
}

.center-xy {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-y-50 {
  min-height: 50px;
}

.min-y-300 {
  min-height: 300px;
}

.p-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-xy-15 {
  padding: 15px;
}

.panel-default > .panel-heading {
  background-color: white;
}

.panel-heading {
  padding: 20px 20px;
  // border: 1px solid #ddd;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.alert-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  .fa {
    font-size: 36px;
    color: #fff;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fa-info {
    background-color: $orange;
  }
  .fa-check {
    background-color: $orange;
  }
  .fa-times {
    background-color: $red;
  }
}

.info-panel {
  b {
    font-size: 24px;
    display: inline-block;
    min-width: 20px;
  }
  ul {
    padding-left: 0;

    li {
      list-style-type: none;
    }
  }

  margin-bottom: 20px;
}

ul.list {
  padding-left: 0;

  li {
    list-style-type: none;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #0000001f;

    //nested list
    ul {
      padding-left: 0;
      li {
        list-style-type: none;
        padding: 0;
        border-bottom: none;
      }
    }

    h6 {
      margin-bottom: 5px;
    }

    .checkbox label {
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}

.checkbox label {
  padding-top: 0;
  padding-bottom: 5px;
}

.code {
  background-color: #bbb;
  padding: 20px;
  border: solid 1px #888;
}

.code-explained {
  padding-top: 20px;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0;
}

.table th .order .caret {
  margin: 0px 0 0px 5px;
  color: #ccc;
}

.table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #ccc;
}

.table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #ccc;
}

.table th.sortable {
  cursor: pointer;
}

.table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

img.fluid {
  width: 100%;
}

.relative {
  position: relative;
}

.modal-backdrop.transparant {
  background-color: transparent;
}

.my-tooltip-button {
  cursor: pointer;
  // display: inline-block;
  // width: 14px;
}

.my-tooltip-button.danger {
  color: #d9534f;
}

.portlet-header {
  display: flex;
  justify-content: space-between;
}
