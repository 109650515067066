$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

.flexRow {
  > div.f-col-sm-6,
  div.f-col-md-6 {
    flex: 0 0 100%;
  }
}

.mega-menu-2 {
  width: 550px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
  .flexRow {
    > div.f-col-sm-6 {
      flex: 0 0 50%;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .flexRow {
    > div.f-col-md-6 {
      flex: 0 0 50%;
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
}
