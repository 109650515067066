$accent_color: darken(#b8d433, 10);
$color1: #2b3d4c;

.topr a,
.topr a:active,
.topr a:focus,
//.topr b,
.topr .mainnav-menu > li.active > a,
.topr .mainnav-menu > li.active > a:hover,
.topr .dropdown-menu > li > a.active,
.topr .dropdown-menu > li > a.active .dropdown-icon,
.mega-menu-menu > li > a.active,
.mega-menu-menu > li > a.active .dropdown-icon,
.dropdown-submenu.active a {
  color: $accent_color;
}

.Toastify__toast-theme--light {
  svg {
    fill: $accent_color;
  }
}

.Toastify__progress-bar--success {
  background-color: $accent_color;
}

.topr .btn-primary,
.topr .mainnav-menu > li > a.btn-primary {
  background-color: $accent_color;
  border-color: $accent_color;
  color: #fff;
}

.topr b {
  font-weight: 800;
  font-size: 14px;
}

.badge-primary.badge {
  background-color: $accent_color;
  color: #000;
}

.badge-not-active.badge {
  background-color: #fff;
  border: solid 1px $color1;
  color: $color1;
}

.badge {
  padding: 6px 0;
}

.topr .mainnav-menu > li.active > a {
  border-bottom-color: $accent_color;
}

.topr a:hover {
  color: $accent_color;
}

.topr .navbar-nav > li.active > a,
.topr .navbar-nav > li.active > a:hover,
.topr .mainnav-menu > li > a.active,
.topr .mainnav-menu > li > a.active :hover {
  color: $accent_color;
}

.topr .btn-primary:hover,
.topr .btn-primary:active:hover,
.topr .btn-primary.active:hover,
.topr .open > .dropdown-toggle.btn-primary:hover,
.topr .btn-primary:active:focus,
.topr .btn-primary.active:focus,
.topr .open > .dropdown-toggle.btn-primary:focus,
.topr .btn-primary:active.focus,
.topr .btn-primary.active.focus,
.topr .open > .dropdown-toggle.btn-primary.focus,
.topr .btn-primary:active,
.topr .btn-primary.active,
.topr .open > .dropdown-toggle.btn-primary,
.topr .btn-primary:focus,
.topr .btn-primary.focus {
  color: #ffffff;
  background-color: $accent_color;
  border-color: darken($accent_color, 20%);
}

.topr .heading-block:after,
.topr .footer .heading-block:after {
  background: $accent_color;
  z-index: 10;
}

.topr.landing .navbar-nav > li.active > a,
.topr.landing .navbar-nav > li.active > a:hover {
  color: $accent_color;
}

.topr .portlet-footer h4 {
  color: $accent_color;
}

.topr .btn-primary.disabled,
.topr .btn-primary[disabled],
.topr fieldset[disabled] .btn-primary,
.topr .btn-primary.disabled:hover,
.topr .btn-primary[disabled]:hover,
.topr fieldset[disabled] .btn-primary:hover,
.topr .btn-primary.disabled:focus,
.topr .btn-primary[disabled]:focus,
.topr fieldset[disabled] .btn-primary:focus,
.topr .btn-primary.disabled.focus,
.topr .btn-primary[disabled].focus,
.topr fieldset[disabled] .btn-primary.focus,
.topr .btn-primary.disabled:active,
.topr .btn-primary[disabled]:active,
.topr fieldset[disabled] .btn-primary:active,
.topr .btn-primary.disabled.active,
.topr .btn-primary[disabled].active,
.topr fieldset[disabled] .btn-primary.active {
  // background-color: lighten($accent_color, 10%);
  // border-color: $accent_color;
  background-color: #fff;
  color: #333;
  border-color: #ccc;
}

.topr .badge.info {
  background-color: $accent_color;
}

.topr .nav-tabs > li.active > a,
.topr .nav-tabs > li.active > a:hover,
.topr .nav-tabs > li.active > a:focus {
  color: $accent_color;
}

.topr .circle-stats {
  .circle-stat {
    background-color: $accent_color;
  }

  .circle-stat.blue {
    background-color: $color1;
  }
}

.topr .circle-stats.even {
  .circle-stat-row:nth-child(even) {
    .circle-stat {
      background-color: $color1;
    }
  }
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: $accent_color;
  border-color: $accent_color;
}

.btn-secondary,
.btn-secondary:hover {
  background-color: $color1;
  border-color: darken($color1, 10%);
}

.react-bootstrap-table img.thumb {
  max-height: 60px;
  cursor: pointer;
}

img.inline {
  max-width: 100%;
}
.cursor {
  cursor: pointer;
}
